import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

const Title = styled('h1')``;
const Description = styled('p')``;

const Header = ({ className, id }) => (
  <header className={className}>
    <Title capitalize translate={id ? 'AUDIT.TITLE' : 'DEMO.TITLE'}>
      {id ? 'AUDIT.TITLE' : 'DEMO.TITLE'}
    </Title>
  </header>
);

Header.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

export default styled(Header)`
  text-align: center;
  color: white;
  padding: 3.2rem 0 3.2rem;

  ${Title} {
    font-size: 2.8rem;
  }

  ${Description} {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2.4rem auto 0;
    max-width: 30rem;
    opacity: 0.6;
  }

  @media screen and (min-width: 600px) {
    padding: 6.4rem 0 5.6rem;

    ${Title} {
      font-size: 4rem;
    }

    ${Description} {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin: 2.4rem auto 0;
      max-width: 70rem;
    }
  }
`;
